import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GreenBtn, md, Copy } from '../common'

export default () => {
  return(
  <Wrap>
    <Copy>
      <h1>REGISTER YOUR BANDS</h1>
      <p>Register to have your guests buy your bands! If you’re an Underwood’s bride or groom, why not register for your wedding bands too? Here’s how it works: </p>
      <Num>01.</Num>
      <p>Register for your wedding bands when you register for your giftware, crystal, flatware and china. </p>
      <Num>02.</Num>
      <p>The bands will show up on your registry and guests can make a payment towards them. Your guests can choose any payment that fits their budget. </p>
      <Num>03.</Num>
      <p>Once the band has been purchased completely we will notify you and you can come and pick it up for your big day!</p>
      <br />
      <div >
      <br />
        <Link css={`color:black;font-size:14px;;`} to={`/registry`}><GreenBtn>Gift Registry</GreenBtn></Link>
      </div>
    </Copy>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Wedding+Anniversary/CTA-Placeholder.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Num = styled.p`
  margin: 0;
  font-weight:700;
`
