import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/wa-greenBar3'
import Register from '../sections/wa-register'
import Milestone from '../sections/wa-milestone'
import { PageHead, GreyBtn, md } from '../common'

export default () => (
  <Layout headImg={'https://s3.amazonaws.com/underwoods/header_weddings_anniversary.jpg'} >
    <SEO
      title="Wedding & Anniversary"
      description="Engaged to be married or looking for an anniversary ring? Underwood’s in Jacksonville and Ponte Vedra, FL has a huge selection of eternity, anniversary and wedding bands in all shapes in sizes." />
    <PageHead>
      <h1>Wedding & Anniversary</h1>
      <p>Underwood's offers a large selection of wedding and anniversary bands in all of our locations in Jacksonville and Ponte Vedra Beach, FL. We have wedding bands in traditional metals such as platinum, yellow gold, white gold and rose gold. We carry a large selection of men’s rings in traditional metals as well as in alternative metals such as titanium, palladium and cobalt chrome. If you are looking for an anniversary ring we have an extensive collection. We have traditional diamond eternity rings as well as colored gemstone rings featuring diamonds, rubies, sapphires and emeralds.</p>
      <div css={`display:flex;justify-content:center;${md}{display:flex;flex-direction:column;}`}>
        <div css={`margin:5px;`}>
          <Link to={`/products`}><GreyBtn >Browse Anniversary Bands</GreyBtn></Link>
        </div>
        <div css={`margin:5px;`}>
          <Link to={`/products`}><GreyBtn >Browse Wedding Bands</GreyBtn></Link>
        </div>
      </div>
    </PageHead>
    <GreenBar />
    <Register />
    <Milestone />
    <JoinUs />
  </Layout>
)
