import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GreyBtn, md, Copy } from '../common'

export default () => {
  return(
  <Wrap>
    <Copy>
      <h1>MILESTONE MEMORIES</h1>
      <p>Diamonds, gemstones, watches and fashion jewelry aren’t just for birthdays and anniversaries. Recognize a milestone moment in you or your loved one’s life by purchasing something that will be remembered forever and passed on from generation to generation. Memorable occasions such as graduations, bat mitzvahs, healing from disease or JUST BECAUSE IT’S TUESDAY, can all be celebrated with fine jewelry and watches.</p>
      <div >
      <br />
        <Link to={`/products`}><GreyBtn>Shop Our Products</GreyBtn></Link>
      </div>
    </Copy>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Wedding+Anniversary/milestone-memories.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`
