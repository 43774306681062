import React from 'react'
import { A, Card, Wrap } from '../common/greenBar'

export default () => (
  <Wrap>
    <Card>
      <A to="/colored-stones">
        <h2>Colored Stones</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Wedding+Anniversary/icon_coloredstones.png" alt="colored stones icon" />
        </div>
        <p>Learn More</p>
      </A>
      </Card>

      <Card css={`border-left:1px solid white;border-right:1px solid white;`}>
      <A to="/trading-selling">
        <h2>Trade in & Up</h2>
        <div>
          <img css={`margin-top:1rem;`} src="https://s3.amazonaws.com/underwoods/Wedding+Anniversary/icon_tradein-and-up.png" alt="trading icon" />
        </div>
        <p>Learn More</p>
      </A>
    </Card>

    <Card>
      <A to="/financing">
        <h2>Financing</h2>
        <div>
          <img src="https://s3.amazonaws.com/underwoods/Wedding+Anniversary/icon_financing.png" alt="financing icon" />
        </div>
        <p>Learn More</p>
      </A>
    </Card>

  </Wrap>
)
